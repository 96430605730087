<script lang="ts">
  import classnames from 'classnames';
  // Utils
  import { dateFullMonthFormat, textEllipsisWithHTML } from '$lib/utils/formatter';
  import { getURLFromContentfulAsset } from '$lib/utils/assets-utils';
  import { lazyLoader} from '$lib/utils/lazyloader'
  // Contentful
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
	import type { INews } from '$lib/utils/contentful/types';
  
  export let lazyLoad: boolean = false;

  export let article: INews;
</script>

<section class={classnames('article-card', $$props.class)}>
  <div class="article-image-wrapper">
    <div class="article-image">
      {#if lazyLoad}
      <img
        class="image"
        use:lazyLoader={getURLFromContentfulAsset(article.fields.thumbnailImage)}
        alt={article.fields.thumbnailImage && article.fields.thumbnailImage.fields.description ? article.fields.thumbnailImage.fields.description : ''}
      />
    {:else}
      <img
        class="image"
        src={getURLFromContentfulAsset(article.fields.thumbnailImage)}
        alt={article.fields.thumbnailImage && article.fields.thumbnailImage.fields.description ? article.fields.thumbnailImage.fields.description : ''}
      />
    {/if}
    </div>
  </div>
  <div class="article-content">
    <div class="article-info">
      <p class="article-date">
        {dateFullMonthFormat(article.fields.publishedDate)}
      </p>
      <a class="article-link" href="/news/{article.fields.slug}">
        <h3 class="article-title">{article.fields.title}</h3>
      </a>
      <p class="article-abstract">
        {@html textEllipsisWithHTML(documentToHtmlString(article.fields.content), 80)}
      </p>
    </div>
  </div>
</section>

<style type="text/scss" lang="scss" scoped>
  .article-card {
    @apply grid;
    @apply relative;
    @apply w-full h-full;
    @apply rounded;
    @apply shadow-card-inset;
    @apply bg-white;

    grid-template-rows: auto 1fr;

    /* Using pseudo elements for hover effects on box-shadow
    is much better for performance
    See: https://tobiasahlin.com/blog/how-to-animate-box-shadow/ */

    &:before,
    &:after {
      @apply duration-500 transition-opacity ease-in-out;
    }

    /* Default State */
    &:before {
      content: '';
      @apply rounded;
      @apply absolute inset-0;
      @apply shadow-card opacity-100;
    }

    &:hover:before {
      @apply opacity-0;
    }

    /* Hover State */
    &:after {
      content: '';
      @apply rounded;
      @apply absolute inset-0;
      @apply shadow-card-hover opacity-0;
    }
    &:hover:after {
      @apply opacity-100;
    }

    &:hover {
      & .image {
        @apply transform scale-105;
      }
    }
  }

  .article-image {
    @apply overflow-hidden;
    @apply rounded-t;
    aspect-ratio: 5/3;
  }

  .image {
    @apply object-cover;
    @apply transition-transform duration-500 ease-in-out;
    will-change: transform;
  }

  .article-content {
    @apply overflow-hidden;
    @apply p-6;
    @apply h-full;
    @apply flex flex-col;
  }
  .article-info {
    @apply flex-grow;
  }

  .article-date {
    @apply uppercase tracking-wide font-semibold;
    @apply text-gray-600 text-sm md:text-base;
  }

  .article-date + .article-link {
    @apply mt-4 md:mt-5;
  }

  .article-title {
    @apply text-md md:text-3xl lg:text-5xl leading-tight md:leading-tight lg:leading-tight;
  }

  .article-link {
    @apply inline-block;
    @apply text-gray-900 font-medium font-serif;

    &:after {
      content: '';
      @apply absolute inset-0;
      @apply z-10;
    }
  }

  .article-link,
  .article-abstract {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  .article-link + .article-abstract {
    @apply mt-3 md:mt-8;
  }

  .article-abstract {
    @apply text-sm md:text-base;
  }
</style>
